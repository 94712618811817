.App {
  text-align: center;
}

.Table-Center {
  text-align-last: center;
}

.Table-Center-admins {
  text-align: center;
}

.wrapper {
  width: 100%;
  font-size: 0;
}
.column {
  display: inline-block;
  min-height: 150px;
  width: 60%;
  margin-bottom: 8px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.App-logo {
  height: 20vmin;
  align-self: center;
  pointer-events: none;
  
}

.table-div {
  padding-left: 200px;
}

.additional-logo {
  height: 25vmin;
  width: 25vmin;
  text-align: right;
  
}

.home-images {
  height: 50vmin;
  align-self: flex-start;
  pointer-events: none;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    
  }
}

.App-header {
  flex-grow: 1;
  background-color: hsl(52, 13%, 23%);
  min-height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(231, 143, 20);
  
}

.nav {
  background-color: hsl(231, 143, 20);
  min-height: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(231, 143, 20);
  
}


.App-logo-left{
  width: 900px;
  -webkit-writing-mode: vertical-lr;
}

.App-logo-right{
  width: 900px;
  align-self: right;
  -webkit-writing-mode: vertical-rl;  
}

.App-link {
  color: #61dafb;
}

nav {
  background: #ee9972;
  padding: 20px;
}
nav a {
  color: #edefee;
  text-decoration: none;
  font-size: 20px;
  margin-right: 20px;
}

.card {
  margin: 20px;
  max-width: 300px;
  border: 1px solid gray;
  padding: 20px;
  border-radius: 50px;
}

.centerWithoutBorderAdmin {
  margin: auto;
  width: 900px;
}

.centerWithoutBorder {
  margin: auto;
  width: 600px;
}

.Dummy-section {
  font-weight: bold;
  line-height: 100px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
